<template>
  <BaseDrawer
    v-if="showDrawer"
    position="right"
    size-class="w-[36rem]"
    class="text-white"
    :overlay="false"
    @update:model-value="hide"
  >
    <template #header>The Tie: On-Chain Signal</template>
    <div class="border-t border-gray-700 bg-gray-900" />
    <div v-if="drawerData.onChainLoading" class="h-full overflow-y-auto border-l border-gray-700">
      <div class="flex justify-center pt-20 text-gray-400">
        <BaseLoader />
      </div>
    </div>
    <ChartOnChainSignal
      :raw-metadata="drawerData.rawMetadata"
      parent="signal"
      v-if="
        !drawerData.onChainLoading && drawerData.rawMetadata?.id != null && drawerData.rawMetadata?.headline != null
      "
    />
    <div
      class="flex items-center justify-center"
      v-if="
        !drawerData.onChainLoading && drawerData.rawMetadata?.id == null && drawerData.rawMetadata?.headline == null
      "
    >
      <NoDataFound />
    </div>
  </BaseDrawer>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ChartOnChainSignal from '@/components/chart/ChartOnChainSignal.vue';

const $store = useStore();
const showDrawer = computed(() => {
  return $store.state.sidebars.onchain;
});
const drawerData = computed(() => {
  return $store.state.onChainSidebarData;
});
function hide() {
  $store.dispatch('resetOnchainSidebar');
}
</script>
